'use client';

import axios from 'axios';
import Link from 'next/link';
import axiosMy from '../../api/axios';
import BuyForm from './ui/BuyForm/BuyForm';
import { Btn } from '@/shared/atoms/Btn/Btn';
import { useMediaQuery } from 'react-responsive';
import CloseIcon from '@/shared/atoms/Icon/CloseIcon';
import useFormStore from './ui/CalloutForm/formStore';
import PopupMakeExchange from './ui/PopupMakeExchange';
import CalloutForm from './ui/CalloutForm/CalloutForm';
import useBuyFormStore from './ui/BuyForm/buyFormStore';
import PopupCreateRequest from './ui/PopupCreateRequest';
import { FC, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogTitle, Stack, Typography } from '@mui/material';

export const Exchange: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [totalAmount, setTotalAmount] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isErrorModal, setIsErrorModalOpen] = useState<boolean>(false);
  const [isExchangePopupOpen, setIsExchangePopupOpen] = useState<boolean>(false);

  const {
    countryData,
    coinsData,
    country,
    crypto,
    resultCurrency,
    amountToGive,
    amountToReceive,
    fetchPaymentData,
    currencyData,
  } = useBuyFormStore();
  const { formValues, formData } = useFormStore();

  const handlePayment = useCallback(async () => {
    try {
      const cryptoId = coinsData?.find((option) => option.name === crypto)?.id;
      const countryId = countryData?.find((option) => option.name === country)?.id;
      const currencyId = currencyData?.find((option) => option.name === resultCurrency)?.id;

      const userInfo = await getUserInfo();
      const paymentData = await fetchPaymentData({
        amountToGive,
        amountToReceive,
        countryId,
        cryptoId,
        currencyId,
        formValues,
        ipAddress: userInfo.ip,
        language: navigator.language,
        region: userInfo.region,
        timezone: userInfo.timezone,
      });

      console.log('Payment data:', paymentData);
    } catch (error) {
      console.log('Error during payment:', error);
    }
  }, [amountToGive, amountToReceive, country, crypto, resultCurrency, formValues, coinsData]);

  const fetchRate = useCallback(async () => {
    try {
      const cryptoId = coinsData?.find((option) => option.name === crypto)?.id;
      const countryId = countryData?.find((option) => option.name === country)?.id;
      const currencyId = currencyData?.find((option) => option.name === resultCurrency)?.id;

      const url = `rate?cryptoId=${cryptoId || coinsData[0]?.id}&currencyId=${
        currencyId || currencyData[4]?.id
      }&countryId=${countryId || countryData[0]?.id}&currencyPrice=1`;
      const { data } = await axiosMy.get(url);
      setTotalAmount(parseFloat(data.price).toFixed(4));
    } catch (error) {
      console.error('Error fetching rate:', error);
    }
  }, [country, resultCurrency, crypto, coinsData, currencyData]);

  async function getUserInfo() {
    try {
      const { data } = await axios.get('https://ipinfo.io/json');
      return {
        ip: data.ip,
        language: navigator.language,
        region: data.region,
        timezone: data.timezone,
      };
    } catch (error) {
      console.error('Error fetching user information:', error);
      return {};
    }
  }
  const handleGoToPayment = async () => {
    const isFormFilled =
      Object.keys(formValues)?.length === formData?.length &&
      Object.values(formValues).every((value) => value !== null && value !== undefined);

    const isAmountValid = amountToGive > 0 && amountToReceive >= 50 && amountToReceive <= 1000;

    if (isFormFilled && isAmountValid) {
      setIsModalOpen(true);
    } else {
      setIsErrorModalOpen(true);
    }
  };

  useEffect(() => {
    fetchRate();
    const interval = setInterval(fetchRate, 60000);
    return () => clearInterval(interval);
  }, [fetchRate]);

  return (
    <Stack spacing={3}>
      <Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          alignItems={'baseline'}
          justifyContent={'space-between'}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={'baseline'}
            spacing={isMobile ? 0 : 3}>
            <Typography
              sx={{
                fontSize: '1.875rem',
                fontWeight: '500',
              }}>
              EXCHANGE
            </Typography>
            <Typography
              sx={{
                color: '#939393',
                fontSize: '0.875rem',
                fontWeight: '300',
              }}>
              Rate: 1 {crypto} = {totalAmount} USD
            </Typography>
          </Stack>
          <Typography
            sx={{
              color: '#16C172',
              fontSize: '0.875rem',
              fontWeight: '300',
              cursor: 'pointer',
            }}
            onClick={() => setIsExchangePopupOpen(true)}>
            How to make an exchange?
          </Typography>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mt: 3 }} spacing={3}>
          <BuyForm />
          <CalloutForm handleCloseModal={() => setIsModalOpen(false)} />
        </Stack>
      </Stack>
      <Stack justifyContent={'center'} alignItems={'center'}>
        <Typography sx={{ mb: '1rem', display: 'flex', flexWrap: 'wrap' }}>
          By clicking on the button you agree with the
          <Link href={'/exchange-rules'}>
            <Typography
              sx={{
                color: '#16C172',
                cursor: 'pointer',
                ml: { md: '0.5rem' },
                mr: '0.5rem',
                display: 'flex',
              }}>
              exchange rules
            </Typography>
          </Link>
          and the
          <Link href={'/aml-policy'}>
            <Typography sx={{ color: '#16C172', cursor: 'pointer', ml: '0.5rem' }}>
              AML policy
            </Typography>
          </Link>
        </Typography>
        <Btn
          // @ts-ignore
          variant={'contained'}
          // @ts-ignore
          color={'active'}
          sx={{ p: '16px 54px', borderRadius: '8px' }}
          handleButtonClick={handleGoToPayment}>
          Go to the Payment
        </Btn>
        <PopupCreateRequest
          isModalOpen={isModalOpen}
          handleModalClose={() => setIsModalOpen(false)}
          isMobile={isMobile}
          handlePayment={handlePayment}
        />

        <PopupMakeExchange
          isExchangePopupOpen={isExchangePopupOpen}
          handleExchangePopupClose={() => setIsExchangePopupOpen(false)}
        />
      </Stack>
      <Dialog onClose={() => setIsErrorModalOpen(false)} open={isErrorModal} fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: { xs: 'flex-start', md: 'center' },
            justifyContent: 'space-between',
            fontSize: '20px',
            p: '12px',
          }}>
          {Object.keys(formValues)?.length !== formData?.length
            ? 'Fill in the form'
            : amountToGive > 0 && amountToReceive >= 50 && amountToReceive <= 1000
            ? ''
            : 'The transaction amount is from 50$ to 1000$'}

          <Stack
            onClick={() => setIsErrorModalOpen(false)}
            sx={{
              border: '1px solid  #E9E9E9',
              p: '8px',
              borderRadius: '14px',
              cursor: 'pointer',
              ':hover': {
                svg: {
                  rotate: '90deg',
                  transition: 'all 0.3s ease',
                },
              },
            }}>
            <CloseIcon />
          </Stack>
        </DialogTitle>
      </Dialog>
    </Stack>
  );
};
