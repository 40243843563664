import { createSvgIcon } from '@mui/material';

//todo create util to generate icons from Assets | create enum for icons | etc
const ClickMouseIcon = createSvgIcon(
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" fill="white" />
    <path
      d="M12.9444 1.42714C12.9444 1.11231 13.1999 0.856196 13.5151 0.856196C13.8299 0.856196 14.0857 1.11231 14.0857 1.42714V6.56433C14.0857 6.87886 13.8299 7.13525 13.5151 7.13525C13.1999 7.13525 12.9444 6.87886 12.9444 6.56433V1.42714ZM13.5151 7.99121C14.3015 7.99121 14.9422 7.35111 14.9422 6.56433V1.42714C14.9422 0.640059 14.3015 -4.43459e-05 13.5151 -4.43459e-05C12.7283 -4.43459e-05 12.0879 0.640059 12.0879 1.42714V6.56433C12.0879 7.35111 12.7283 7.99121 13.5151 7.99121Z"
      fill="#16C172"
    />
    <path
      d="M12.9444 16.441C12.9444 16.1262 13.1999 15.8701 13.5151 15.8701C13.8299 15.8701 14.0857 16.1262 14.0857 16.441V21.5785C14.0857 21.893 13.8299 22.1491 13.5151 22.1491C13.1999 22.1491 12.9444 21.893 12.9444 21.5785V16.441ZM12.0879 21.5785C12.0879 22.3653 12.7283 23.0054 13.5151 23.0054C14.3015 23.0054 14.9422 22.3653 14.9422 21.5785V16.441C14.9422 15.6542 14.3015 15.0141 13.5151 15.0141C12.7283 15.0141 12.0879 15.6542 12.0879 16.441V21.5785Z"
      fill="#242121"
    />
    <path
      d="M16.6036 8.41431C16.496 8.30669 16.4366 8.16358 16.4366 8.01093C16.4366 7.85829 16.496 7.71518 16.6036 7.60726L20.2361 3.97476C20.3437 3.86653 20.4874 3.8072 20.6394 3.8072C20.7921 3.8072 20.9352 3.86653 21.0437 3.97476C21.1514 4.08238 21.2107 4.2255 21.2107 4.37814C21.2107 4.53049 21.1514 4.67359 21.0437 4.78181L17.4106 8.41431C17.1951 8.63016 16.8197 8.63046 16.6036 8.41431ZM20.6394 2.95096C20.2587 2.95096 19.9001 3.09943 19.6303 3.36895L15.9981 7.00204C15.7286 7.27096 15.5801 7.62932 15.5801 8.01093C15.5801 8.39195 15.7286 8.75061 15.9981 9.01983C16.2676 9.28935 16.6256 9.43811 17.0073 9.43811C17.3886 9.43811 17.7466 9.28935 18.0162 9.01983L21.6486 5.38703C21.9182 5.11782 22.0666 4.75946 22.0666 4.37814C22.0666 3.99713 21.9182 3.63846 21.6486 3.36895C21.3797 3.09943 21.0211 2.95096 20.6394 2.95096Z"
      fill="#242121"
    />
    <path
      d="M10.4264 15.3984L6.79424 19.0309C6.57869 19.2467 6.20243 19.2467 5.98658 19.0309C5.87865 18.9229 5.81933 18.7798 5.81933 18.6272C5.81933 18.4749 5.87865 18.3314 5.98718 18.2238L9.61938 14.5913C9.73058 14.4801 9.87697 14.4244 10.0231 14.4244C10.1691 14.4244 10.3152 14.4801 10.4264 14.5913C10.5344 14.6989 10.5937 14.8424 10.5937 14.995C10.5937 15.147 10.5344 15.2905 10.4264 15.3984ZM11.4502 14.995C11.4502 14.6137 11.3018 14.255 11.0322 13.9858C10.4759 13.4295 9.57048 13.4301 9.01386 13.9858L5.38137 17.6177C5.11185 17.8875 4.96338 18.2459 4.96338 18.6272C4.96338 19.0085 5.11185 19.3672 5.38137 19.6361C5.65118 19.9056 6.00925 20.0541 6.39056 20.0541C6.77158 20.0541 7.13024 19.9056 7.39916 19.6361L11.0322 16.0039C11.3018 15.7344 11.4502 15.376 11.4502 14.995Z"
      fill="#242121"
    />
    <path
      d="M24.1615 11.5029C24.1615 11.8175 23.9051 12.0736 23.5903 12.0736H18.4531C18.1382 12.0736 17.8824 11.8175 17.8824 11.5029C17.8824 11.1881 18.1382 10.932 18.4531 10.932H23.5903C23.9051 10.932 24.1615 11.1881 24.1615 11.5029ZM17.0259 11.5029C17.0259 12.2894 17.6666 12.9298 18.4531 12.9298H23.5903C24.3773 12.9298 25.0174 12.2894 25.0174 11.5029C25.0174 10.7158 24.3773 10.0757 23.5903 10.0757H18.4531C17.6666 10.0757 17.0259 10.7158 17.0259 11.5029Z"
      fill="#242121"
    />
    <path
      d="M3.43939 10.932H8.57628C8.89141 10.932 9.14692 11.1881 9.14692 11.5029C9.14692 11.8175 8.89141 12.0736 8.57628 12.0736H3.43939C3.12426 12.0736 2.86875 11.8175 2.86875 11.5029C2.86875 11.1881 3.12426 10.932 3.43939 10.932ZM3.43939 12.9298H8.57628C9.36306 12.9298 10.0035 12.2894 10.0035 11.5029C10.0035 10.7158 9.36306 10.0757 8.57628 10.0757H3.43939C2.65261 10.0757 2.01221 10.7158 2.01221 11.5029C2.01221 12.2894 2.65261 12.9298 3.43939 12.9298Z"
      fill="#242121"
    />
    <path
      d="M5.98718 3.97476C6.09838 3.86325 6.24448 3.80751 6.39056 3.80751C6.53665 3.80751 6.68304 3.86325 6.79424 3.97476L10.4264 7.60726C10.5344 7.71518 10.5937 7.85829 10.5937 8.01093C10.5937 8.16358 10.5344 8.30669 10.4264 8.41431C10.2112 8.63016 9.83493 8.63016 9.61938 8.41431L5.98658 4.78181C5.87865 4.67359 5.81933 4.53049 5.81933 4.37814C5.81933 4.2255 5.87865 4.08238 5.98718 3.97476ZM9.01386 9.01983C9.28337 9.28935 9.64233 9.43811 10.0231 9.43811C10.4044 9.43811 10.7624 9.28935 11.0322 9.01983C11.3018 8.75061 11.4502 8.39195 11.4502 8.01093C11.4502 7.62932 11.3018 7.27096 11.0322 7.00204L7.39976 3.36895C6.84314 2.81292 5.93769 2.81262 5.38137 3.36895C5.11185 3.63846 4.96338 3.99712 4.96338 4.37814C4.96338 4.75946 5.11185 5.11782 5.38137 5.38703L9.01386 9.01983Z"
      fill="#242121"
    />
    <path
      d="M28.4001 24.7152C28.279 24.8061 28.1994 24.9412 28.1783 25.0912C28.1568 25.2414 28.1959 25.3932 28.2868 25.5145L32.6703 31.3484L28.4409 34.5254L24.058 28.6918C23.9456 28.5424 23.7745 28.4637 23.601 28.4637C23.4814 28.4637 23.3616 28.5009 23.2584 28.5782L19.4026 31.4749L17.3616 15.3758L32.2562 21.8182L28.4001 24.7152ZM33.9514 21.6173C33.9275 21.4154 33.7981 21.2413 33.6112 21.1605L16.8965 13.9304C16.7083 13.8493 16.4904 13.8753 16.3267 13.9981C16.1628 14.1212 16.0775 14.3231 16.1031 14.5261L18.394 32.5935C18.4196 32.795 18.5499 32.9685 18.7375 33.0469C18.9244 33.1268 19.1405 33.1 19.303 32.9778L23.488 29.8339L27.8709 35.6676C27.9833 35.8169 28.1544 35.8956 28.3279 35.8956C28.4469 35.8956 28.5673 35.8584 28.6705 35.7808L33.8122 31.9179C33.9332 31.8273 34.0131 31.6919 34.0346 31.5422C34.0554 31.3923 34.0164 31.2399 33.9257 31.1192L29.5428 25.2849L33.7278 22.1408C33.8903 22.0186 33.9752 21.8191 33.9514 21.6173Z"
      fill="#242121"
    />
    <path
      d="M17.2513 32.9155H6.63263C6.28337 32.9155 6 32.6321 6 32.2829C6 31.9334 6.28337 31.65 6.63263 31.65H17.2513C17.6006 31.65 17.8837 31.9334 17.8837 32.2829C17.8837 32.6321 17.6006 32.9155 17.2513 32.9155Z"
      fill="#16C172"
    />
    <path
      d="M12.3244 36H0.632629C0.283369 36 0 35.7169 0 35.3671C0 35.0176 0.283369 34.7347 0.632629 34.7347H12.3244C12.6734 34.7347 12.9568 35.0176 12.9568 35.3671C12.9568 35.7169 12.6734 36 12.3244 36Z"
      fill="#16C172"
    />
  </svg>,
  'ClickMouse',
);

export default ClickMouseIcon;
