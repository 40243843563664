import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import Link from 'next/link';
import { Btn } from '@/shared/atoms/Btn/Btn';
import useFormStore from './CalloutForm/formStore';
import useBuyFormStore from './BuyForm/buyFormStore';
import CloseIcon from '@/shared/atoms/Icon/CloseIcon';

interface PopupCreateRequestProps {
  isModalOpen: boolean;
  isMobile: boolean;
  handlePayment: () => void;
  handleModalClose: () => void;
}

const PopupCreateRequest: FC<PopupCreateRequestProps> = ({
  isModalOpen,
  handleModalClose,
  isMobile,
  handlePayment,
}) => {
  const { formValues } = useFormStore();
  const { country, crypto, resultCurrency, amountToGive } = useBuyFormStore();
  const isFormEmpty = Object.values(formValues).every(
    (value) => value === null || value === undefined || value === '',
  );

  return (
    <>
      <Dialog open={isModalOpen} onClose={handleModalClose} maxWidth="xs" fullWidth>
        <DialogTitle
          sx={{
            fontSize: '22px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography variant="h6">CONFIRMATION</Typography>
          <Stack
            sx={{
              border: '1px solid  #E9E9E9',
              p: '8px',
              borderRadius: '14px',
              ':hover': {
                rotate: '90deg',
                transition: 'all 0.3s ease',
              },
            }}>
            <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer' }} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack sx={{ mb: '1rem' }} spacing={2} direction={'row'} alignItems={'center'}>
              <Stack>
                <Typography sx={{ fontWeight: '300', fontSize: '10px' }}>EXCHANGING</Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    fontWeight: '500',
                    fontSize: '14px',
                    gap: isMobile ? '6px' : '25px',
                    color: 'black',
                    flexDirection: 'row',
                  }}>
                  {amountToGive}
                  <ul
                    style={{
                      display: 'flex',
                      gap: '24px',
                      listStyle: isMobile ? 'none' : 'inherit',
                    }}>
                    <li style={{ color: 'gray' }}>
                      <span style={{ color: 'black' }}> {crypto}</span>
                    </li>
                  </ul>
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ mb: '1rem' }} spacing={2} direction={'row'} alignItems={'center'}>
              <Stack
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                <Typography sx={{ fontWeight: '300', fontSize: '10px' }}>FOR</Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    fontWeight: '500',
                    fontSize: '14px',
                    color: 'black',
                    flexDirection: isMobile ? 'column' : 'row',
                    flexWrap: 'nowrap',
                  }}>
                  {resultCurrency}
                  <ul
                    style={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      gap: isMobile ? '0px' : '24px',
                      listStyle: isMobile ? 'none' : 'inherit',
                    }}>
                    <li style={{ color: 'gray', marginLeft: isMobile ? '0px' : '24px' }}>
                      <span style={{ color: 'black' }}> {country ? country : 'Country'}</span>
                    </li>
                    {Object.entries(formValues)
                      .filter(([, value]) => value !== 'false' && value !== '')
                      .map(
                        ([label, value]) =>
                          (typeof value === 'string' || typeof value === 'number') && (
                            <li key={label} style={{ color: 'gray' }}>
                              <span style={{ color: 'black' }}>{value}</span>
                            </li>
                          ),
                      )}
                  </ul>
                </Typography>
              </Stack>
            </Stack>
          </DialogContentText>
          <Typography
            sx={{
              fontWeight: '300',
              fontSize: '14px',
              color: '#939393',
              mb: '1rem',
            }}
            textAlign={'center'}>
            To complete the order click proceed to checkout
          </Typography>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ gap: '12px' }}>
            <Link
              style={{ width: '100%' }}
              href={
                !amountToGive || !crypto || !resultCurrency || !country || isFormEmpty
                  ? ''
                  : '/payment'
              }>
              <Btn
                // @ts-ignore
                variant={'contained'}
                handleButtonClick={handlePayment}
                disabled={isFormEmpty}
                // @ts-ignore
                color={'active'}
                fullWidth
                sx={{
                  p: '16px',
                  borderRadius: '8px',
                  pointerEvents: isFormEmpty ? 'none' : 'auto',
                  opacity: isFormEmpty ? 0.5 : 1,
                }}>
                Confirm
              </Btn>
            </Link>
            <Btn
              fullWidth
              sx={{
                background: '#fff',
                color: '#16C172 ',
                p: '16px',
                borderRadius: '8px',
                border: '1px solid #16C172 ',
              }}
              handleButtonClick={handleModalClose}>
              Close
            </Btn>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PopupCreateRequest;
