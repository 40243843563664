import { create } from 'zustand';

interface IFormStore {
  formData: any[];
  formValues: { [key: string]: string };
  selectedCurrencyCode: string | null;
  setFormData: (data: any[]) => void;
  setFormValues: (values: { [key: string]: string }) => void;
  setSelectedCurrencyCode: (code: string | null) => void;
  resetForm: () => void;
  handleFieldChange: (fieldName: string, value: string) => void;
}

const useFormStore = create<IFormStore>((set) => ({
  formData: [],
  formValues: {},
  selectedCurrencyCode: null,
  setFormData: (data) => set({ formData: data }),
  setFormValues: (values) => set({ formValues: values }),
  setSelectedCurrencyCode: (code) => set({ selectedCurrencyCode: code }),
  resetForm: () => set({ formValues: {} }),
  handleFieldChange: (fieldName, value) =>
    set((state) => ({
      formValues: {
        ...state.formValues,
        [fieldName]: value,
      },
    })),
}));

export default useFormStore;
