import { message, Spin } from 'antd';
import axios from '../../../../api/axios';
import useBuyFormStore from './buyFormStore';
import { Coin, Country, Currency } from './buyForm.type';
import InputAdornment from '@mui/material/InputAdornment';
import { FC, useEffect, useState, useCallback } from 'react';
import { Box, Grid, Input, Typography } from '@mui/material';
import { Selector } from '@/shared/molecules/Selector/Selector';
import { RadioBtn } from '@/shared/molecules/RadioBtn/RadioBtn';

const BuyForm: FC = () => {
  const {
    amountToGive,
    countryData,
    coinsData,
    country,
    crypto,
    resultCurrency,
    amountToReceive,
    currencyData,
    setAmountToGive,
    setAmountToReceive,
    fetchCryptoData,
    fetchCountryData,
    fetchCurrencyData,
  } = useBuyFormStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  const fetchRate = useCallback(async () => {
    setLoading(true);
    try {
      const cryptoId = coinsData?.find((option: Coin) => option.name === crypto)?.id;
      const countryId = countryData?.find((option: Country) => option.name === country)?.id;
      const currencyId = currencyData?.find(
        (option: Currency) => option.name === resultCurrency,
      )?.id;

      if (
        (amountToReceive >= 50 && amountToReceive <= 1000) ||
        cryptoId ||
        countryId ||
        currencyId
      ) {
        const { data } = await axios.get(
          `rate?cryptoId=${cryptoId ? cryptoId : coinsData[0].id}&currencyId=${
            currencyId ? currencyId : currencyData[0].id
          }&countryId=${countryId ? countryId : countryData[0].id}&currencyPrice=${amountToGive}`,
        );
        setAmountToReceive(Number(data.price.toFixed(4)));
      } else {
        setAmountToReceive(0);
      }
    } catch (error) {
      setAmountToReceive(0);
      message.error('Error fetching exchange rate data');
      console.error('Error fetching exchange rate data:', error);
    } finally {
      setLoading(false);
    }
  }, [amountToGive, country, crypto, resultCurrency, countryData, currencyData, coinsData]);

  const debounceFetchRate = useCallback(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newTimeout = setTimeout(fetchRate, 500);
    setDebounceTimeout(newTimeout);
  }, [
    amountToGive,
    country,
    crypto,
    resultCurrency,
    countryData,
    currencyData,
    coinsData,
    fetchRate,
  ]);

  const fetchData = async () => {
    await Promise.all([fetchCountryData(), fetchCryptoData(), fetchCurrencyData()]);
  };

  useEffect(() => {
    fetchData();
  }, [fetchCountryData, fetchCryptoData, fetchCurrencyData]);

  useEffect(() => {
    if (amountToGive > 0) {
      debounceFetchRate();
    }
  }, [
    amountToGive,
    country,
    crypto,
    resultCurrency,
    countryData,
    currencyData,
    coinsData,
    debounceFetchRate,
  ]);

  useEffect(() => {
    setAmountToGive(0);
    setAmountToReceive(0);
  }, [crypto, country, resultCurrency]);

  useEffect(() => {
    const interval = setInterval(fetchRate, 60000);
    return () => clearInterval(interval);
  }, [fetchRate]);

  return (
    <Box
      sx={{
        width: { md: '100%' },
        backgroundColor: '#FFF',
        p: 3,
        borderRadius: '0.5rem',
        border: '1px solid #E9E9E9',
      }}>
      <Typography
        sx={{
          fontWeight: '500',
          marginBottom: '15px',
        }}>
        Step 1: Select information
      </Typography>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <RadioBtn />
        <Selector />
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            placeholder={`0 ${crypto} `}
            inputMode="numeric"
            value={amountToGive}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;
              const formattedValue = value.replace(',', '.');
              if (/^\d*[.,]?\d*$/.test(formattedValue)) {
                // @ts-ignore
                setAmountToGive(formattedValue);
              }
            }}
            sx={{
              height: '100%',
              width: '100%',
              borderRadius: '0.5rem',
              border: '1px solid #E9E9E9',
              '&:hover, &:focus': {
                border: '1px solid #16C172',
              },
              p: 1,
              pl: 2,
              pr: 2,
              display: { xs: 'none', md: 'flex' },
              'input[type=number]': {
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '-moz-appearance': 'textfield',
                appearance: 'textfield',
              },
            }}
            disableUnderline
            endAdornment={
              <InputAdornment position="end">
                {loading ? (
                  <Spin
                    indicator={
                      <span className="ant-spin-dot ant-spin-dot-spin">
                        <i className="ant-spin-dot-item" style={{ backgroundColor: '#16C172' }} />
                        <i className="ant-spin-dot-item" style={{ backgroundColor: '#16C172' }} />
                        <i className="ant-spin-dot-item" style={{ backgroundColor: '#16C172' }} />
                        <i className="ant-spin-dot-item" style={{ backgroundColor: '#16C172' }} />
                      </span>
                    }
                  />
                ) : (
                  ''
                )}
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            placeholder={`${amountToGive === 0 ? 0 : amountToReceive} USD`}
            error={amountToReceive < 50 || amountToReceive > 1000 ? true : false}
            readOnly
            sx={{
              height: '100%',
              width: '100%',
              borderRadius: '0.5rem',
              border: '1px solid #E9E9E9',
              p: 1.5,
              pl: 2,
              pr: 2,
            }}
            disableUnderline
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BuyForm;
