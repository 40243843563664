import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { FC, useEffect, useState, useRef, useCallback } from 'react';
import { Box, Grid, Modal, Stack, TextField, Typography } from '@mui/material';

import useFormStore from './formStore';
import CloseIcon from '@/shared/atoms/Icon/CloseIcon';
import useBuyFormStore from '../BuyForm/buyFormStore';
import UploadIcon from '@/shared/atoms/Icon/UploadIcon';

interface CalloutFormProps {
  handleCloseModal: () => void;
}

const CalloutForm: FC<CalloutFormProps> = ({ handleCloseModal }) => {
  const { resultCurrency, currencyData, countryData } = useBuyFormStore();
  const { formData, setFormData, setSelectedCurrencyCode, resetForm, handleFieldChange } =
    useFormStore();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const fileInputRefs = useRef<Record<string, HTMLInputElement | null>>({});
  const [uploadedPhotos, setUploadedPhotos] = useState<Record<string, string>>({});

  useEffect(() => {
    const selectedCurrency = currencyData?.find(
      (currency: any) => currency.name === resultCurrency,
    );

    if (selectedCurrency) {
      setFormData(selectedCurrency.fields);
      setSelectedCurrencyCode(resultCurrency);
      resetForm();
      const refs: Record<string, HTMLInputElement | null> = {};
      selectedCurrency.fields
        .filter((field: any) => field.type === 'file')
        .forEach((field: any) => {
          refs[field.formName] = null;
        });
      resetForm();
    } else {
      setFormData(countryData[0]?.currencies[0]?.fields);
      setSelectedCurrencyCode(resultCurrency);
      resetForm();
      const refs: Record<string, HTMLInputElement | null> = {};
      countryData[0]?.currencies[0]?.fields
        .filter((field: any) => field.type === 'file')
        .forEach((field: any) => {
          refs[field.formName] = null;
        });
      fileInputRefs.current = refs;
    }
  }, [resultCurrency, currencyData, formData, setSelectedCurrencyCode, resetForm]);

  const handleClick = useCallback(
    (formName: string) => {
      if (fileInputRefs.current[formName]) {
        fileInputRefs.current[formName]?.click();
      }
    },
    [fileInputRefs],
  );

  const handleFileChange = useCallback(
    (formName: string, file: File | any) => {
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setUploadedPhotos((prevPhotos) => ({
          ...prevPhotos,
          [formName]: imageUrl,
        }));

        handleFieldChange(formName, file);
      }
    },
    [handleFieldChange],
  );

  const handlePhotoClick = useCallback(
    (photoUrl: string) => {
      setSelectedPhoto(photoUrl);
      setIsModalOpen(true);
    },
    [setIsModalOpen, setSelectedPhoto],
  );

  const handleDeletePhoto = useCallback(
    (formName: string) => {
      setUploadedPhotos((prevPhotos) => {
        const newPhotos = { ...prevPhotos };
        delete newPhotos[formName];
        return newPhotos;
      });
      handleFieldChange(formName, '');
    },
    [setUploadedPhotos, handleFieldChange],
  );

  const handleCloseModalInternal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedPhoto(null);
  }, [setIsModalOpen, setSelectedPhoto]);

  useEffect(() => {
    if (formData) {
      formData.forEach((field) => {
        if (!field.optional || field.value === '' || field.value === null) {
          handleFieldChange(field.publicName, 'false');
        }
      });
    }
  }, [formData, handleFieldChange]);

  return (
    <Box
      sx={{
        width: { md: '100%' },
        backgroundColor: '#FFF',
        minHeight: '12.5rem',
        p: 3,
        borderRadius: '0.5rem',
        border: '1px solid #E9E9E9',
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: '500',
            }}>
            Step 2: Enter your details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {formData
              ?.sort((a: any, b: any) => a.order - b.order)
              .map((field) => (
                <Grid item key={field.id} xs={12} md={6}>
                  {field.type !== 'file' ? (
                    <TextField
                      type={field.type}
                      fullWidth
                      sx={{
                        height: '100%',
                        borderRadius: '0.5rem',
                        border: '1px solid #E9E9E9',
                        transition: 'border-color 0.3s',
                        '&:hover, &:focus': {
                          border: '1px solid #16C172',
                          '& .MuiInputLabel-root': {
                            color: '#16C172',
                          },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiInputBase-input': {
                          transform: 'translateY(10px)',
                        },
                        ...(focusedField === field.formName && {
                          '& .MuiInputLabel-root': {
                            transform: 'translate(12px, 10px)',
                            fontSize: '12px',
                          },
                        }),
                        '& .MuiInputLabel-root': {
                          transform: 'translate(12px, 10px)',
                          fontSize: '12px',
                        },
                      }}
                      label={field.optional ? `${field.publicName} *` : field.publicName}
                      onChange={(e) => handleFieldChange(field.formName, e.target.value)}
                      onFocus={() => setFocusedField(field.formName)}
                      onBlur={() => setFocusedField(null)}
                      required={field.option === false && !uploadedPhotos[field.formName]}
                    />
                  ) : (
                    <>
                      {uploadedPhotos[field.formName] ? (
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <img
                            src={uploadedPhotos[field.formName]}
                            alt="Uploaded"
                            style={{
                              width: '90px',
                              height: '90px',
                              borderRadius: '0.5rem',
                              border: '1px solid #16C172',
                              cursor: 'pointer',
                            }}
                            onClick={() => handlePhotoClick(uploadedPhotos[field.formName])}
                          />
                          <DeleteOutlineIcon
                            onClick={() => handleDeletePhoto(field.formName)}
                            sx={{
                              position: 'absolute',
                              bottom: '10px',
                              right: '5px',
                              cursor: 'pointer',
                              background: '#fff',
                              borderRadius: '50%',
                              padding: '4px',
                              border: '1px solid #16C172',
                            }}
                          />
                        </div>
                      ) : (
                        <Stack
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          sx={{
                            p: '16px',
                            borderRadius: '0.5rem',
                            border: '1px solid #16C172',
                            background: '#16C1721A',
                            gap: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleClick(field.formName)}>
                          <UploadIcon />
                          <label
                            style={{ color: '#16C172' }}
                            htmlFor={`upload-${field.formName}-input`}>
                            {field.publicName}
                          </label>
                          <input
                            // @ts-ignore
                            ref={(el) => (fileInputRefs.current[field.formName] = el)}
                            id={`upload-${field.formName}-input`}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                              const file = e.target.files?.[0];
                              handleFileChange(field.formName, file);
                            }}
                          />
                        </Stack>
                      )}
                    </>
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Modal open={isModalOpen} onClose={handleCloseModalInternal}>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            maxWidth: '400px',
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 2,
            outline: 'none',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}>
          {selectedPhoto && (
            <>
              <CloseIcon
                onClick={handleCloseModalInternal}
                sx={{
                  cursor: 'pointer',
                  p: '8px',
                  borderRadius: '14px',
                  border: '1px solid #E9E9E9',
                  mb: '12px',
                }}
              />
              <img
                src={selectedPhoto}
                alt="Clicked"
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '0.5rem',
                  border: '1px solid #16C172',
                  cursor: 'pointer',
                }}
                onClick={handleCloseModalInternal}
              />
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default CalloutForm;
