import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CompIcon from '@/shared/atoms/Icon/CompIcon';
import EarthIcon from '@/shared/atoms/Icon/EarthIcon';
import CloseIcon from '@/shared/atoms/Icon/CloseIcon';
import MoneyIcon from '@/shared/atoms/Icon/MoneyIcon';
import FillInIcon from '@/shared/atoms/Icon/FillInIcon';
import MethodsIcon from '@/shared/atoms/Icon/MethodsIcon';
import ExchangeIcon from '@/shared/atoms/Icon/ExchangeIcon';
import ClickMouseIcon from '@/shared/atoms/Icon/ClickMouseIcon';
import ProceedPayIcon from '@/shared/atoms/Icon/ProceedPayIcon';

interface Step {
  text: string;
  icon: JSX.Element;
}

interface PopupMakeExchangeProps {
  isExchangePopupOpen: boolean;
  handleExchangePopupClose: () => void;
}

const steps: Step[] = [
  {
    text: 'State which jurisdiction you are located in',
    icon: <EarthIcon sx={{ width: '36px', height: '36px' }} />,
  },
  {
    text: 'Pick which Crypto you are selling',
    icon: <ExchangeIcon sx={{ width: '36px', height: '36px' }} />,
  },
  {
    text: 'From the available payment methods choose one which suits you best',
    icon: <MethodsIcon sx={{ width: '36px', height: '36px' }} />,
  },
  {
    text: 'Fill in the required transaction and contact information',
    icon: <FillInIcon sx={{ width: '36px', height: '36px' }} />,
  },
  {
    text: 'Confirm your details carefully!',
    icon: <CompIcon sx={{ width: '36px', height: '36px' }} />,
  },
  {
    text: 'Proceed with payment (sending the crypto to the exchange wallet)',
    icon: <ProceedPayIcon sx={{ width: '36px', height: '36px' }} />,
  },
  {
    text: 'Click on “I paid” when you have transferred the funds',
    icon: <ClickMouseIcon sx={{ width: '36px', height: '36px' }} />,
  },
  {
    text: 'If you have completed all previous steps correctly, simply wait for the funds to get to your account (that can take >10 minutes at times)',
    icon: <MoneyIcon sx={{ width: '36px', height: '36px' }} />,
  },
];

const PopupMakeExchange: FC<PopupMakeExchangeProps> = ({
  isExchangePopupOpen,
  handleExchangePopupClose,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Drawer
        anchor="right"
        open={isExchangePopupOpen}
        onClose={handleExchangePopupClose}
        sx={{ width: '100%', padding: '16px', boxSizing: 'border-box' }}>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              p: '8px',
              borderRadius: '14px',
              mb: '12px',
            }}>
            <CloseIcon onClick={handleExchangePopupClose} style={{ cursor: 'pointer' }} />
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              gap: '80px',
              whiteSpace: 'nowrap',
              fontSize: '18px',
            }}>
            HOW TO MAKE EXCHANGE?
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '17px' }}>
          {steps.map((step, index) => (
            <DialogContentText
              key={index}
              sx={{ display: 'flex', alignItems: 'flex-start', gap: '20px' }}>
              {step.icon}
              <Typography sx={{ color: 'black', fontWeight: '300', fontSize: '14px' }}>
                {step.text}
              </Typography>
            </DialogContentText>
          ))}
        </DialogContent>
      </Drawer>
    );
  }

  return (
    <Dialog onClose={handleExchangePopupClose} open={isExchangePopupOpen} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '80px',
          whiteSpace: 'nowrap',
          fontSize: '20px',
        }}>
        HOW TO MAKE EXCHANGE?
        <Stack
          onClick={handleExchangePopupClose}
          sx={{
            border: '1px solid  #E9E9E9',
            p: '8px',
            borderRadius: '14px',
            cursor: 'pointer',
            ':hover': {
              rotate: '90deg',
              transition: 'all 0.3s ease',
            },
          }}>
          <CloseIcon />
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '20px', position: 'relative' }}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <DialogContentText
              sx={{ display: 'flex', alignItems: 'center', gap: '20px', position: 'relative' }}>
              <Stack
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ zIndex: '2' }}>
                {step.icon}
              </Stack>
              <Typography sx={{ color: 'black', fontWeight: '300', fontSize: '14px' }}>
                {step.text}
              </Typography>
            </DialogContentText>
          </React.Fragment>
        ))}
        <Stack
          sx={{
            width: '200%',
            position: 'absolute',
            background: '#E9E9E9',
            height: '1px',
            rotate: '90deg',
            left: '-402px',
            zIndex: '1',
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PopupMakeExchange;
