import { FC, useState } from 'react';
import CloseIcon from '@/shared/atoms/Icon/CloseIcon';
import { Stack, Grid, Dialog, DialogTitle } from '@mui/material';
import { Btn } from '@/shared/atoms/Btn/Btn';

enum TransactionType {
  Buy = 'buy',
  Sell = 'sell',
}

export const RadioBtn: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<TransactionType>(TransactionType.Sell);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Grid item xs={12} md={4}>
      <Stack
        direction={'row'}
        spacing={0.5}
        p={0.4}
        sx={{ borderRadius: '8px', border: '1px solid #E9E9E9' }}>
        <Btn
          handleButtonClick={() => setOpenModal(true)}
          sx={{
            py: '17px',
            px: '34px',
            border: '1px solid #E9E9E9',
            width: { xs: '100%', md: 'auto' },
          }}>
          Buy
        </Btn>
        <Btn
          sx={{
            py: '17px',
            px: '34px',
            border: '1px solid #E9E9E9',
            width: { xs: '100%', md: 'auto' },
            backgroundColor: '#272727',
            color: '#FFFFFF',
          }}>
          Sell
        </Btn>
      </Stack>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '8px',
            widthL: '100%',
            fontSize: { xs: '14px', md: '24px' },
            fontWeight: '500',
          }}>
          We will return the purchase option soon
          <Stack
            onClick={handleCloseModal}
            sx={{
              cursor: 'pointer',
              p: '8px',
              border: '1px solid #E3E3E3',
              borderRadius: '8px',
            }}>
            <CloseIcon
              sx={{
                ':hover': {
                  rotate: '90deg',
                  transition: 'all 0.3s ease',
                },
              }}
            />
          </Stack>
        </DialogTitle>
      </Dialog>
    </Grid>
  );
};
