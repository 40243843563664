export interface CoinData {
  id: number;
  name: string;
  icon: string;
  memo: string | null;
  available: boolean;
  order: number;
  walletAddress: string;
}

export enum ChangeTarget {
  Crypto = 'crypto',
  Country = 'country',
  CountryCurrency = 'countryCurrency',
}

export type SelectorProps = {
  coinsData: CoinData[];
  crypto: string;
  country: string;
  resultCurrency: string;
  setCrypto: React.Dispatch<React.SetStateAction<string>>;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  setResultCurrency: React.Dispatch<React.SetStateAction<string>>;
};
