import { createSvgIcon } from '@mui/material';

//todo create util to generate icons from Assets | create enum for icons | etc
const UploadIcon = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 13L4.66667 9M9 13L13 9M9 13V0.333334M17 8.33333V17H1V8.33333"
      stroke="#16C172"
      strokeWidth="1.5"
    />
  </svg>,
  'Upload',
);

export default UploadIcon;
