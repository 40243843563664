import { createSvgIcon } from '@mui/material';

//todo create util to generate icons from Assets | create enum for icons | etc
const ProceedPayIcon = createSvgIcon(
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.8877 7.20007C27.0002 7.53757 27.3377 7.87507 27.6752 7.87507H28.1252"
      stroke="#16C172"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5 9C29.5 8.4375 29.025 7.875 28.4625 7.875H28.125"
      stroke="#16C172"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8877 7.20007C26.8877 7.08757 26.8877 7.08757 26.8877 6.97507C26.8877 6.52507 27.2252 6.07507 27.7877 6.07507H28.3502H29.3627"
      stroke="#16C172"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8877 9.56252H28.3502H28.5752C29.0252 9.56252 29.3875 9.44988 29.5 8.99988"
      stroke="#16C172"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3501 5.17505V6.07505"
      stroke="#16C172"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3501 9.5625V10.575"
      stroke="#16C172"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.875 4.61255V20.025C34.875 21.2625 33.8625 22.275 32.625 22.275H3.375C2.1375 22.275 1.125 21.2625 1.125 20.025V4.61255C1.125 3.37505 2.1375 2.36255 3.375 2.36255H32.625C33.8625 2.36255 34.875 3.37505 34.875 4.61255Z"
      stroke="#272727"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.175 12.4875H3.9375V15.8625H32.175V12.4875Z"
      stroke="#272727"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1875 19.4625H32.0625"
      stroke="#272727"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.9375 5.17505H13.3875"
      stroke="#16C172"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.9375 8.77502H19.125"
      stroke="#16C172"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.4126 28.6875V33.75H30.0376C30.4876 33.75 30.8251 33.4125 30.8251 32.9625V29.475C30.8251 29.025 30.4876 28.6875 30.0376 28.6875H6.4126Z"
      stroke="#272727"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.4125 28.6875V33.6375L1.125 31.1625L6.4125 28.6875Z"
      stroke="#272727"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1125 33.6375V27.5625C27.1125 26.775 26.4375 26.2125 25.7625 26.2125H21.9375"
      stroke="#272727"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.8252 30.15V32.175H34.0877C34.5377 32.175 34.9877 31.8375 34.9877 31.275V30.9375C34.9877 30.4875 34.6502 30.0375 34.0877 30.0375L30.8252 30.15Z"
      stroke="#272727"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'ProceedPay',
);

export default ProceedPayIcon;
