import { FC } from 'react';
import { IBtnProps } from './btn.type';
import { SxProps, Theme, Box } from '@mui/material';

export const Btn: FC<IBtnProps> = ({ children, handleButtonClick, fullWidth, ...props }) => {
  const styles: SxProps<Theme> = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 47px',
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: '500',
    fontSize: '14px',
    boxShadow: 'none',
    textTransform: 'none',
    width: fullWidth ? '100%' : 'auto',
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
  };

  const customizeTheme = (v: any) => {
    switch (v.variant) {
      case 'contained':
        if (v.color === 'active') {
          styles.background = '#16C172';
          styles.color = '#FFFFFF';
          break;
        }
        break;
      default:
        break;
    }
  };
  customizeTheme(props);

  const styledBtn = { ...styles, ...props.sx };

  return (
    <Box onClick={handleButtonClick} sx={styledBtn} component="span">
      {children}
    </Box>
  );
};
