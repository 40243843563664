import { Select, Space, Image } from 'antd';
import { ChangeTarget } from './selector.type';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { Box, FormControl, Grid, Input, Stack } from '@mui/material';
import useBuyFormStore from '../../../widgets/Exchange/ui/BuyForm/buyFormStore';

import styles from './selector.module.css';
import ArrowIcon from '@/shared/atoms/Icon/ArrowIcon';

const { Option } = Select;

export const Selector: React.FC = () => {
  const [selectedCurrencies, setSelectedCurrencies] = useState<number[]>([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState<string | number>('');
  const [selectedCryptoOption, setSelectedCryptoOption] = useState<string | number>('');
  const [selectedCountryCurrencyOption, setSelectedCountryCurrencyOption] = useState<
    string | number
  >('');

  const {
    coinsData,
    setCountry,
    setCrypto,
    setResultCurrency,
    countryData,
    currencyData,
    amountToGive,
    setAmountToGive,
  } = useBuyFormStore();

  const currencyResult = countryData?.find((country) => country.name === selectedCountryOption)
    ?.currencies[0];
  useEffect(() => {
    if (countryData && countryData.length > 0) {
      handleChange(countryData[0].name, ChangeTarget.Country);
    }
    if (coinsData && coinsData.length > 0) {
      handleChange(coinsData[0].name, ChangeTarget.Crypto);
    }
    if (currencyResult && currencyResult?.name) {
      // @ts-ignore
      handleChange(currencyResult?.name, ChangeTarget.CountryCurrency);
    }
  }, [countryData, coinsData, currencyData]);

  const handleChange = (value: string | number, target: ChangeTarget) => {
    switch (target) {
      case ChangeTarget.Crypto:
        setCrypto(value as string);
        setSelectedCryptoOption(value);
        break;
      case ChangeTarget.CountryCurrency:
        setResultCurrency(value as string);
        setSelectedCountryCurrencyOption(value);

        break;
      case ChangeTarget.Country:
        const selectedCountry = countryData?.find((country) => country.name === value);
        if (selectedCountry) {
          setCountry(selectedCountry.name);
          setSelectedCountryOption(value);
          setSelectedCurrencies(selectedCountry.currencies.map((currency) => currency.id) || []);
          setSelectedCountryCurrencyOption(selectedCountry.currencies[0]?.name);
          setResultCurrency(selectedCountry.currencies[0]?.name);
        }
        break;
    }
  };

  return (
    <>
      <Grid item xs={12} md={8} sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Stack
          alignItems={'center'}
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          sx={{ width: '100%' }}>
          <Box key="country" sx={{ width: '100%' }}>
            <FormControl fullWidth>
              <Select
                onChange={(value) => handleChange(value as string, ChangeTarget.Country)}
                value={selectedCountryOption}
                bordered={false}
                className={styles.selector}
                style={{
                  borderRadius: '0.5rem',
                  height: '62px',
                  border: '1px solid #E9E9E9',
                  width: '100%',
                }}
                dropdownStyle={{
                  border: '1px solid #16C172',
                }}
                suffixIcon={<ArrowIcon />}>
                {countryData?.map((country) => (
                  <Option key={country.name} value={country.name}>
                    <Space align="center" style={{ width: '100%' }}>
                      <Image
                        preview={false}
                        width={36}
                        height={36}
                        src={country.icon}
                        alt={country.name}
                      />
                      {country.name}
                    </Space>
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box key="crypto" sx={{ width: '100%' }}>
            <FormControl fullWidth>
              <Select
                onChange={(value) => handleChange(value as string, ChangeTarget.Crypto)}
                value={selectedCryptoOption}
                bordered={false}
                className={styles.selector}
                style={{
                  borderRadius: '0.5rem',
                  height: '62px',
                  border: '1px solid #E9E9E9',
                  width: '100%',
                }}
                dropdownStyle={{
                  border: '1px solid #16C172',
                }}
                suffixIcon={<ArrowIcon />}>
                {coinsData?.map((coin) => (
                  <Option key={coin.name} value={coin.name}>
                    <Space align="center" style={{ width: '100%' }}>
                      <Image
                        preview={false}
                        width={36}
                        height={36}
                        src={coin.icon}
                        alt={coin.name}
                      />
                      {coin.name}
                    </Space>
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box key="amountToGive" sx={{ width: '100%' }}>
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setAmountToGive(Number(e.target.value))
              }
              type="number"
              placeholder={`0`}
              inputMode="numeric"
              value={amountToGive === 0 ? '' : amountToGive}
              sx={{
                borderRadius: '0.5rem',
                border: '1px solid #E9E9E9',
                p: 1.5,
                pl: 2,
                pr: 2,
                width: '100%',
                'input[type=number]': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  '-moz-appearance': 'textfield',
                  appearance: 'textfield',
                },
              }}
              disableUnderline
            />
          </Box>
          <Box key="countryCurrency" sx={{ width: '100%' }}>
            <FormControl fullWidth>
              <Select
                onChange={(value) => handleChange(value as string, ChangeTarget.CountryCurrency)}
                value={selectedCountryCurrencyOption}
                bordered={false}
                className={styles.selector}
                style={{
                  borderRadius: '0.5rem',
                  height: '62px',
                  border: '1px solid #E9E9E9',
                  width: '100%',
                }}
                dropdownStyle={{
                  border: '1px solid #16C172',
                }}
                suffixIcon={<ArrowIcon />}>
                {currencyData
                  ?.filter((currency) => selectedCurrencies.includes(currency.id))
                  .map((option) => (
                    <Option key={option?.name} value={option?.name}>
                      <Space align="center" style={{ width: '100%' }}>
                        <Image
                          preview={false}
                          width={36}
                          height={36}
                          src={option?.icon}
                          alt={option?.name}
                        />
                        {option?.name}
                      </Space>
                    </Option>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} md={8} sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Stack
          alignItems={'center'}
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          sx={{ width: '100%' }}>
          <Box key="crypto" sx={{ width: '100%' }}>
            <FormControl fullWidth>
              <Select
                onChange={(value) => handleChange(value as string, ChangeTarget.Crypto)}
                value={selectedCryptoOption}
                bordered={false}
                className={styles.selector}
                style={{
                  borderRadius: '0.5rem',
                  height: '62px',
                  border: '1px solid #E9E9E9',
                  width: '100%',
                }}
                dropdownStyle={{
                  border: '1px solid #16C172',
                }}
                suffixIcon={<ArrowIcon />}>
                {coinsData?.map((coin) => (
                  <Option key={coin.name} value={coin.name}>
                    <Space align="center" style={{ width: '100%' }}>
                      <Image
                        preview={false}
                        width={36}
                        height={36}
                        src={coin.icon}
                        alt={coin.name}
                      />
                      {coin.name}
                    </Space>
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box key="countryCurrency" sx={{ width: '100%' }}>
            <FormControl fullWidth>
              <Select
                onChange={(value) => handleChange(value as string, ChangeTarget.CountryCurrency)}
                value={selectedCountryCurrencyOption}
                bordered={false}
                className={styles.selector}
                style={{
                  borderRadius: '0.5rem',
                  height: '62px',
                  border: '1px solid #E9E9E9',
                  width: '100%',
                }}
                dropdownStyle={{
                  border: '1px solid #16C172',
                }}
                suffixIcon={<ArrowIcon />}>
                {currencyData
                  ?.filter((currency) => selectedCurrencies.includes(currency.id))
                  .map((option) => (
                    <Option key={option?.name} value={option?.name}>
                      <Space align="center" style={{ width: '100%' }}>
                        <Image
                          preview={false}
                          width={36}
                          height={36}
                          src={option?.icon}
                          alt={option?.name}
                        />
                        {option?.name}
                      </Space>
                    </Option>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Input
            type="number"
            value={amountToGive}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setAmountToGive(Number(e.target.value))}
            sx={{
              height: '100%',
              width: '100%',
              borderRadius: '0.5rem',
              border: '1px solid #E9E9E9',
              p: 1,
              pl: 2,
              pr: 2,
              display: { xs: 'flex', md: 'none' },
            }}
            disableUnderline
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Box sx={{ width: '100%' }}>
          <FormControl fullWidth>
            <Select
              onChange={(value) => handleChange(value as string, ChangeTarget.Country)}
              value={selectedCountryOption}
              className={styles.selector}
              bordered={false}
              style={{
                borderRadius: '0.5rem',
                height: '62px',
                border: '1px solid #E9E9E9',
                width: '100%',
              }}
              dropdownStyle={{
                border: '1px solid #16C172',
              }}
              suffixIcon={<ArrowIcon />}>
              {countryData?.map((country) => (
                <Option key={country.name} value={country.name}>
                  <Space align="center" style={{ width: '100%' }}>
                    <Image
                      width={36}
                      height={36}
                      src={country.icon}
                      alt={country.name}
                      preview={false}
                    />
                    {country.name}
                  </Space>
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </>
  );
};
