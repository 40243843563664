import { createSvgIcon } from '@mui/material';

//todo create util to generate icons from Assets | create enum for icons | etc
const FillInIcon = createSvgIcon(
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" fill="white" />
    <path
      d="M32.7981 32.6763H10.9315C10.5976 32.6763 10.3271 32.4056 10.3271 32.0717C10.3271 31.738 10.5976 31.4673 10.9315 31.4673H32.7981C33.132 31.4673 33.4022 31.738 33.4022 32.0717C33.4022 32.4056 33.132 32.6763 32.7981 32.6763Z"
      fill="#16C172"
    />
    <path
      d="M14.7728 36H3.6046C3.27071 36 3 35.7298 3 35.3959C3 35.0623 3.27071 34.7916 3.6046 34.7916H14.7728C15.1067 34.7916 15.3771 35.0623 15.3771 35.3959C15.3771 35.7298 15.1067 36 14.7728 36Z"
      fill="#16C172"
    />
    <path
      d="M11.4778 11.237H21.8562C22.1578 11.237 22.4023 11.4815 22.4023 11.783V13.64L29.461 7.68129L22.4023 1.72157V3.57827C22.4023 3.88005 22.1578 4.12431 21.8562 4.12431H11.4778V11.237ZM21.856 15.3613C21.7779 15.3613 21.6998 15.3449 21.6264 15.3107C21.4335 15.2211 21.3102 15.0277 21.3102 14.8153V12.3291H10.9318C10.6302 12.3291 10.3857 12.0846 10.3857 11.783V3.57827C10.3857 3.27673 10.6302 3.03222 10.9318 3.03222H21.3102V0.546013C21.3102 0.333606 21.4335 0.140199 21.6264 0.0508175C21.8195 -0.0388193 22.046 -0.00824833 22.2084 0.128648L30.6597 7.26392C30.7827 7.36769 30.8536 7.52051 30.8536 7.68129C30.8536 7.84207 30.7827 7.99463 30.6597 8.0984L22.2084 15.2327C22.1079 15.3174 21.9826 15.3613 21.856 15.3613Z"
      fill="#272727"
    />
    <path
      d="M5.3206 11.237H7.92549V4.12434H5.3206V11.237ZM8.47152 12.3291H4.77456C4.47278 12.3291 4.22852 12.0846 4.22852 11.7831V3.5783C4.22852 3.27677 4.47278 3.03225 4.77456 3.03225H8.47152C8.77331 3.03225 9.01757 3.27677 9.01757 3.5783V11.7831C9.01757 12.0846 8.77331 12.3291 8.47152 12.3291Z"
      fill="#16C172"
    />
    <path
      d="M5.49416 20.7088L12.5526 26.6683V24.8118C12.5526 24.5103 12.7972 24.2658 13.0987 24.2658H23.4773V17.1529H13.0987C12.7972 17.1529 12.5526 16.9083 12.5526 16.6068V14.7504L5.49416 20.7088ZM13.0989 28.3901C12.9723 28.3901 12.847 28.346 12.7466 28.2612L4.29522 21.1262C4.17245 21.0224 4.10156 20.8696 4.10156 20.7088C4.10156 20.548 4.17245 20.3955 4.29522 20.2917L12.7466 13.1577C12.9091 13.0206 13.1359 12.9902 13.3288 13.0796C13.5214 13.1693 13.6447 13.3624 13.6447 13.5748V16.0608H24.0234C24.3247 16.0608 24.5694 16.3053 24.5694 16.6068V24.8118C24.5694 25.1136 24.3247 25.3579 24.0234 25.3579H13.6447V27.8441C13.6447 28.0565 13.5214 28.2496 13.3288 28.339C13.2554 28.3732 13.177 28.3901 13.0989 28.3901Z"
      fill="#272727"
    />
    <path
      d="M27.1565 24.2658H29.7617V17.1529H27.1565V24.2658ZM30.3077 25.3579H26.6105C26.3087 25.3579 26.0645 25.1137 26.0645 24.8119V16.6068C26.0645 16.3053 26.3087 16.0608 26.6105 16.0608H30.3077C30.6092 16.0608 30.8538 16.3053 30.8538 16.6068V24.8119C30.8538 25.1137 30.6092 25.3579 30.3077 25.3579Z"
      fill="#272727"
    />
  </svg>,
  'FillIn',
);

export default FillInIcon;
