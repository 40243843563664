import { createSvgIcon } from '@mui/material';

//todo create util to generate icons from Assets | create enum for icons | etc
const CompIcon = createSvgIcon(
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" fill="white" />
    <path
      d="M8.4375 5.625C8.74816 5.625 9 5.37316 9 5.0625C9 4.75184 8.74816 4.5 8.4375 4.5C8.12684 4.5 7.875 4.75184 7.875 5.0625C7.875 5.37316 8.12684 5.625 8.4375 5.625Z"
      fill="#272727"
    />
    <path
      d="M6.1875 5.625C6.49816 5.625 6.75 5.37316 6.75 5.0625C6.75 4.75184 6.49816 4.5 6.1875 4.5C5.87684 4.5 5.625 4.75184 5.625 5.0625C5.625 5.37316 5.87684 5.625 6.1875 5.625Z"
      fill="#272727"
    />
    <path
      d="M3.9375 5.625C4.24816 5.625 4.5 5.37316 4.5 5.0625C4.5 4.75184 4.24816 4.5 3.9375 4.5C3.62684 4.5 3.375 4.75184 3.375 5.0625C3.375 5.37316 3.62684 5.625 3.9375 5.625Z"
      fill="#272727"
    />
    <path
      d="M34.3125 7.875H1.6875C1.377 7.875 1.125 7.623 1.125 7.3125C1.125 7.002 1.377 6.75 1.6875 6.75H34.3125C34.623 6.75 34.875 7.002 34.875 7.3125C34.875 7.623 34.623 7.875 34.3125 7.875Z"
      fill="#272727"
    />
    <path
      d="M1.6875 24.75C1.377 24.75 1.125 24.498 1.125 24.1875V3.9375C1.125 3.00712 1.88212 2.25 2.8125 2.25H33.1875C34.1179 2.25 34.875 3.00712 34.875 3.9375V14.0625C34.875 14.373 34.623 14.625 34.3125 14.625C34.002 14.625 33.75 14.373 33.75 14.0625V3.9375C33.75 3.62812 33.498 3.375 33.1875 3.375H2.8125C2.502 3.375 2.25 3.62812 2.25 3.9375V24.1875C2.25 24.498 1.998 24.75 1.6875 24.75ZM24.75 34.875H11.8125C11.502 34.875 11.25 34.623 11.25 34.3125C11.25 34.002 11.502 33.75 11.8125 33.75H12.9375C13.8679 33.75 14.625 32.9929 14.625 32.0625V30.9375C14.625 30.627 14.877 30.375 15.1875 30.375C15.498 30.375 15.75 30.627 15.75 30.9375V32.0625C15.75 32.6959 15.5396 33.2798 15.1864 33.75H20.8136C20.4604 33.2798 20.25 32.6959 20.25 32.0625V28.6875C20.25 28.377 20.502 28.125 20.8125 28.125C21.123 28.125 21.375 28.377 21.375 28.6875V32.0625C21.375 32.9929 22.1321 33.75 23.0625 33.75H24.75C25.0605 33.75 25.3125 34.002 25.3125 34.3125C25.3125 34.623 25.0605 34.875 24.75 34.875Z"
      fill="#272727"
    />
    <path
      d="M20.8125 29.25H2.8125C1.88212 29.25 1.125 28.4929 1.125 27.5625V24.1875C1.125 23.877 1.377 23.625 1.6875 23.625H17.4375C17.748 23.625 18 23.877 18 24.1875C18 24.498 17.748 24.75 17.4375 24.75H2.25V27.5625C2.25 27.8719 2.502 28.125 2.8125 28.125H20.8125C21.123 28.125 21.375 28.377 21.375 28.6875C21.375 28.998 21.123 29.25 20.8125 29.25Z"
      fill="#272727"
    />
    <path
      d="M20.8125 15.75H19.6875C19.377 15.75 19.125 15.498 19.125 15.1875C19.125 14.877 19.377 14.625 19.6875 14.625H20.8125C21.123 14.625 21.375 14.877 21.375 15.1875C21.375 15.498 21.123 15.75 20.8125 15.75ZM30.9375 11.25H19.6875C19.377 11.25 19.125 10.998 19.125 10.6875C19.125 10.377 19.377 10.125 19.6875 10.125H30.9375C31.248 10.125 31.5 10.377 31.5 10.6875C31.5 10.998 31.248 11.25 30.9375 11.25ZM30.9375 13.5H19.6875C19.377 13.5 19.125 13.248 19.125 12.9375C19.125 12.627 19.377 12.375 19.6875 12.375H30.9375C31.248 12.375 31.5 12.627 31.5 12.9375C31.5 13.248 31.248 13.5 30.9375 13.5Z"
      fill="#272727"
    />
    <path
      d="M8.4375 15.75H5.0625C4.752 15.75 4.5 15.498 4.5 15.1875C4.5 14.877 4.752 14.625 5.0625 14.625H8.4375C8.748 14.625 9 14.877 9 15.1875C9 15.498 8.748 15.75 8.4375 15.75ZM16.3125 11.25H5.0625C4.752 11.25 4.5 10.998 4.5 10.6875C4.5 10.377 4.752 10.125 5.0625 10.125H16.3125C16.623 10.125 16.875 10.377 16.875 10.6875C16.875 10.998 16.623 11.25 16.3125 11.25ZM16.3125 13.5H5.0625C4.752 13.5 4.5 13.248 4.5 12.9375C4.5 12.627 4.752 12.375 5.0625 12.375H16.3125C16.623 12.375 16.875 12.627 16.875 12.9375C16.875 13.248 16.623 13.5 16.3125 13.5Z"
      fill="#272727"
    />
    <path
      d="M27 30.375C22.6575 30.375 19.125 26.8414 19.125 22.5C19.125 18.1586 22.6575 14.625 27 14.625C28.521 14.625 29.9959 15.0592 31.266 15.8794C31.527 16.0481 31.6013 16.3957 31.4325 16.6567C31.2638 16.9155 30.9173 16.9943 30.6551 16.8233C29.5684 16.1213 28.3039 15.75 27 15.75C23.2774 15.75 20.25 18.7774 20.25 22.5C20.25 26.2226 23.2774 29.25 27 29.25C30.7226 29.25 33.75 26.2226 33.75 22.5C33.75 21.7778 33.6364 21.0679 33.4136 20.3884C33.3169 20.0925 33.4778 19.7753 33.7725 19.6785C34.0673 19.5795 34.3856 19.7426 34.4824 20.0362C34.7422 20.8305 34.875 21.6585 34.875 22.5C34.875 26.8414 31.3425 30.375 27 30.375Z"
      fill="#16C172"
    />
    <path
      d="M26.4377 25.5364C26.4332 25.5364 26.4287 25.5364 26.4242 25.5364C26.2611 25.5319 26.1092 25.4588 26.0046 25.3339L23.1921 21.9589C22.9941 21.7193 23.0267 21.3649 23.2641 21.1658C23.5037 20.9678 23.857 21.0004 24.0572 21.2378L26.4569 24.1189L33.4487 16.3958C33.6602 16.1663 34.0157 16.1528 34.243 16.3609C34.4714 16.5713 34.4871 16.9268 34.2767 17.1563L26.8517 25.3542C26.7449 25.4712 26.5964 25.5364 26.4377 25.5364Z"
      fill="#16C172"
    />
  </svg>,
  'Comp',
);

export default CompIcon;
