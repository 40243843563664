import { createSvgIcon } from '@mui/material';

//todo create util to generate icons from Assets | create enum for icons | etc
const MoneyIcon = createSvgIcon(
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3026 32.9155H2.6839C2.33464 32.9155 2.05127 32.6321 2.05127 32.2829C2.05127 31.9334 2.33464 31.65 2.6839 31.65H13.3026C13.6519 31.65 13.935 31.9334 13.935 32.2829C13.935 32.6321 13.6519 32.9155 13.3026 32.9155Z"
      fill="#16C172"
    />
    <path
      d="M21.3146 36H9.62286C9.2736 36 8.99023 35.7169 8.99023 35.3671C8.99023 35.0176 9.2736 34.7347 9.62286 34.7347H21.3146C21.6636 34.7347 21.947 35.0176 21.947 35.3671C21.947 35.7169 21.6636 36 21.3146 36Z"
      fill="#16C172"
    />
    <path
      d="M30.1234 33.0881L4.16357 22.1471L9.8555 8.641L35.8145 19.5818L30.1234 33.0881Z"
      fill="white"
    />
    <path
      d="M4.91187 21.8426L29.8182 32.3395L35.0666 19.8868L10.1599 9.38995L4.91187 21.8426ZM30.1229 33.6599C30.0476 33.6599 29.972 33.6449 29.9011 33.615L3.94097 22.6739C3.65006 22.5513 3.51374 22.2163 3.63634 21.9254L9.3283 8.41933C9.38718 8.27952 9.49929 8.16902 9.63991 8.11175C9.77999 8.05475 9.93729 8.05556 10.0774 8.11444L36.0372 19.0552C36.3275 19.1778 36.4647 19.5131 36.341 19.804L30.6491 33.3104C30.5899 33.4499 30.4791 33.5604 30.3385 33.6177C30.2694 33.6459 30.1963 33.6599 30.1229 33.6599Z"
      fill="#272727"
    />
    <path
      d="M29.9837 18.8053L14.4845 12.2731C13.8153 13.6627 12.1943 14.3243 10.7481 13.7982L8.47803 19.1841C9.86458 19.8526 10.523 21.4746 9.99551 22.9244L25.4947 29.4565C26.1639 28.067 27.7849 27.4051 29.2315 27.9307L31.5021 22.545C30.115 21.8769 29.4565 20.2545 29.9837 18.8053Z"
      fill="#E9E9E9"
    />
    <path
      d="M29.9837 18.8053L14.4845 12.2731C13.8153 13.6627 12.1943 14.3243 10.7481 13.7982L8.47803 19.1841C9.86458 19.8526 10.523 21.4746 9.99551 22.9244L25.4947 29.4565C26.1639 28.067 27.7849 27.4051 29.2315 27.9307L31.5021 22.545C30.115 21.8769 29.4565 20.2545 29.9837 18.8053Z"
      fill="white"
    />
    <path
      d="M10.3241 22.7734L25.3732 29.116C26.1217 27.8219 27.6433 27.2014 29.0801 27.6023L31.1603 22.6664C29.8692 21.9184 29.2516 20.3963 29.6549 18.9563L14.6056 12.6139C13.8571 13.9083 12.3361 14.5291 10.8987 14.1269L8.81845 19.063C10.1096 19.8115 10.7274 21.3339 10.3241 22.7734ZM25.4942 29.7234C25.4601 29.7234 25.4248 29.7169 25.391 29.7024L9.89177 23.1703C9.76083 23.1151 9.69604 22.967 9.74471 22.8334C10.2284 21.5035 9.63391 20.0379 8.3619 19.4244C8.23391 19.3628 8.17664 19.2114 8.23203 19.0805L10.5021 13.6948C10.5572 13.5638 10.7056 13.4985 10.839 13.5477C12.1653 14.0301 13.6301 13.4329 14.2442 12.1574C14.3058 12.0294 14.4572 11.9719 14.5881 12.0275L30.0873 18.5597C30.2182 18.6148 30.283 18.763 30.2341 18.8966C29.7504 20.2267 30.3449 21.692 31.6174 22.3045C31.7465 22.3663 31.8029 22.5174 31.7465 22.6486L29.4772 28.0343C29.4218 28.1653 29.2737 28.2295 29.1403 28.1817C27.8132 27.6996 26.3489 28.2973 25.7348 29.5723C25.6891 29.6675 25.594 29.7234 25.4942 29.7234Z"
      fill="#272727"
    />
    <path
      d="M23.9683 22.5419C23.073 24.6657 20.6255 25.6619 18.5017 24.7668C16.3774 23.8718 15.3812 21.4243 16.2765 19.3002C17.1716 17.1764 19.6191 16.18 21.7432 17.0753C23.8672 17.9704 24.8634 20.4181 23.9683 22.5419Z"
      fill="#95D6E6"
    />
    <path
      d="M20.1224 17.0832C19.6328 17.0832 19.1432 17.1781 18.6761 17.3682C17.727 17.7546 16.9855 18.4873 16.5873 19.4313C16.1896 20.3752 16.1832 21.4179 16.5693 22.367C16.9556 23.3159 17.6883 24.0577 18.6323 24.4553C20.581 25.2765 22.835 24.3596 23.6564 22.4106C24.0546 21.4666 24.0607 20.4239 23.6746 19.4751C23.2883 18.5262 22.5559 17.7844 21.6116 17.3865C21.1322 17.1843 20.6273 17.0832 20.1224 17.0832ZM20.1162 25.4321C19.5327 25.4321 18.9401 25.3184 18.3699 25.078C17.2594 24.6099 16.3977 23.7377 15.9436 22.6216C15.4895 21.5056 15.497 20.2793 15.9648 19.1688C16.9309 16.8767 19.5817 15.7978 21.874 16.7638C22.9845 17.2319 23.8462 18.1044 24.3006 19.2204C24.7547 20.3365 24.7472 21.5626 24.2791 22.6727C23.5534 24.3948 21.8767 25.4321 20.1162 25.4321Z"
      fill="#272727"
    />
    <path
      d="M31.142 26.9592L3.68896 20.6403L6.97641 6.35691L34.4303 12.6759L31.142 26.9592Z"
      fill="white"
    />
    <path
      d="M4.37351 20.2114L30.7138 26.2738L33.7439 13.1046L7.40448 7.04216L4.37351 20.2114ZM31.1413 27.5308C31.0983 27.5308 31.0552 27.5256 31.0122 27.516L3.55992 21.197C3.41231 21.1631 3.28406 21.0717 3.20367 20.9435C3.12328 20.8147 3.09721 20.6596 3.13136 20.5117L6.4188 6.22857C6.48978 5.92099 6.79684 5.72874 7.10416 5.79972L34.5586 12.1187C34.8651 12.1897 35.056 12.4964 34.9861 12.804L31.6978 27.0871C31.6656 27.235 31.5741 27.3633 31.4451 27.4434C31.3537 27.5009 31.2488 27.5308 31.1413 27.5308Z"
      fill="#272727"
    />
    <path
      d="M28.5502 12.9123L12.1594 9.13978C11.7386 10.6234 10.2553 11.5539 8.7402 11.284L7.4292 16.98C8.90959 17.4002 9.83719 18.8852 9.5667 20.404L25.9575 24.1765C26.3783 22.6926 27.8611 21.7623 29.3767 22.0315L30.6866 16.3358C29.207 15.9161 28.2797 14.4308 28.5502 12.9123Z"
      fill="#E9E9E9"
    />
    <path
      d="M28.5502 12.9123L12.1594 9.13978C11.7386 10.6234 10.2553 11.5539 8.7402 11.284L7.4292 16.98C8.90959 17.4002 9.83719 18.8852 9.5667 20.404L25.9575 24.1765C26.3783 22.6926 27.8611 21.7623 29.3767 22.0315L30.6866 16.3358C29.207 15.9161 28.2797 14.4308 28.5502 12.9123Z"
      fill="white"
    />
    <path
      d="M9.86537 20.1987L25.7802 23.8618C26.2954 22.4586 27.6876 21.5872 29.172 21.7337L30.3741 16.5139C28.9731 15.999 28.1033 14.6055 28.253 13.1178L12.3381 9.45447C11.8227 10.8582 10.4314 11.7321 8.94612 11.5818L7.74454 16.8021C9.14508 17.3178 10.0151 18.7114 9.86537 20.1987ZM25.9582 24.4434C25.9386 24.4434 25.9185 24.4409 25.8983 24.4366L9.50778 20.6639C9.36905 20.6322 9.28005 20.4972 9.30505 20.3571C9.55295 18.9638 8.71542 17.6224 7.3571 17.2366C7.22051 17.1979 7.13797 17.0586 7.16996 16.9202L8.48096 11.2244C8.51269 11.086 8.64713 10.9959 8.78774 11.0217C10.1783 11.2691 11.5173 10.4289 11.9034 9.06704C11.9421 8.93046 12.0817 8.84872 12.2199 8.87991L28.6106 12.6527C28.7494 12.6844 28.8384 12.8194 28.8134 12.9592C28.5652 14.353 29.4027 15.6941 30.7627 16.0791C30.8971 16.1178 30.9804 16.2574 30.9482 16.3959L29.6374 22.0913C29.6057 22.2298 29.4705 22.319 29.3306 22.294C27.9406 22.0459 26.6011 22.8877 26.2147 24.2495C26.1817 24.3662 26.0752 24.4434 25.9582 24.4434Z"
      fill="#272727"
    />
    <path
      d="M23.2662 17.6265C22.7491 19.8726 20.5092 21.2745 18.2631 20.7575C16.0169 20.2404 14.6153 18.0005 15.1321 15.7544C15.6491 13.508 17.8891 12.1064 20.1355 12.6234C22.3816 13.1404 23.7832 15.3804 23.2662 17.6265Z"
      fill="#16C172"
    />
    <path
      d="M19.1921 12.8535C18.4809 12.8535 17.7824 13.0527 17.1645 13.4393C16.2964 13.9827 15.6914 14.8318 15.4615 15.8304C15.2319 16.8287 15.4043 17.8569 15.9479 18.7253C16.4913 19.5937 17.3406 20.1987 18.339 20.4286C19.3375 20.6585 20.3654 20.4858 21.2341 19.9419C22.1026 19.3985 22.7073 18.5495 22.9372 17.5511C23.167 16.5526 22.9944 15.5244 22.4508 14.656C21.9074 13.7875 21.058 13.1826 20.0597 12.953C19.7712 12.8863 19.4806 12.8535 19.1921 12.8535ZM19.1996 21.2024C18.8657 21.2024 18.5266 21.165 18.1876 21.087C17.0132 20.8166 16.0146 20.1054 15.3752 19.0837C14.7361 18.0623 14.5329 16.8529 14.8031 15.6787C15.0736 14.5046 15.7847 13.5058 16.8061 12.8664C17.8278 12.2273 19.037 12.0243 20.2111 12.2942C21.3855 12.5647 22.3844 13.2761 23.0235 14.2973C23.6628 15.319 23.8661 16.5281 23.5956 17.7025C23.1157 19.7876 21.2538 21.2024 19.1996 21.2024Z"
      fill="#272727"
    />
    <path d="M29.7398 19.8374H0.571289V4.1715H29.7398V19.8374Z" fill="white" />
    <path
      d="M1.14324 19.2659H29.1687V4.74328H1.14324V19.2659ZM29.7404 20.4092H0.571608C0.255957 20.4092 0 20.1532 0 19.8376V4.17166C0 3.856 0.255957 3.60005 0.571608 3.60005H29.7404C30.056 3.60005 30.312 3.856 30.312 4.17166V19.8376C30.312 20.1532 30.056 20.4092 29.7404 20.4092Z"
      fill="#272727"
    />
    <path
      d="M23.8632 5.8271H6.44827C6.36841 7.47365 5.0878 8.79836 3.49609 8.88037V15.1275C5.0878 15.2103 6.36841 16.5351 6.44827 18.1819H23.8632C23.9427 16.5353 25.2234 15.2103 26.8151 15.1275L26.8153 8.88037C25.2236 8.79836 23.9427 7.47365 23.8632 5.8271Z"
      fill="white"
    />
    <path
      d="M6.70625 17.9013H23.6057C23.7985 16.3173 25.0004 15.0749 26.5348 14.8719L26.535 9.1364C25.0006 8.93421 23.7985 7.69203 23.6057 6.10786H6.70625C6.51348 7.69203 5.31165 8.93421 3.77721 9.1364V14.8719C5.31165 15.0749 6.51348 16.3173 6.70625 17.9013ZM23.8636 18.4626H6.44868C6.29892 18.4626 6.17551 18.3449 6.16852 18.1954C6.09485 16.6825 4.94006 15.4841 3.48198 15.408C3.33303 15.4002 3.21582 15.2771 3.21582 15.1276V8.88043C3.21582 8.73121 3.33303 8.60807 3.48198 8.60028C4.94006 8.52499 6.09485 7.32692 6.16852 5.81346C6.17551 5.66423 6.29892 5.54646 6.44868 5.54646H23.8636C24.0133 5.54646 24.1368 5.66423 24.1437 5.81372C24.2171 7.32691 25.3719 8.52499 26.83 8.60028C26.9792 8.60807 27.0962 8.73121 27.0962 8.88043V15.1276C27.0959 15.2771 26.979 15.4002 26.8297 15.408C25.3717 15.4841 24.2171 16.6825 24.1437 18.1954C24.1368 18.3449 24.0133 18.4626 23.8636 18.4626Z"
      fill="#272727"
    />
    <path
      d="M19.6266 12.0045C19.6266 14.4682 17.6921 16.4653 15.3056 16.4653C12.9191 16.4653 10.9844 14.4682 10.9844 12.0045C10.9844 9.54089 12.9191 7.54374 15.3056 7.54374C17.6921 7.54374 19.6266 9.54089 19.6266 12.0045Z"
      fill="#16C172"
    />
    <path
      d="M15.3051 7.89945C13.1181 7.89945 11.3393 9.74121 11.3393 12.0051C11.3393 14.2687 13.1181 16.1104 15.3051 16.1104C17.4918 16.1104 19.2709 14.2687 19.2709 12.0051C19.2709 9.74121 17.4918 7.89945 15.3051 7.89945ZM15.3051 16.821C12.7264 16.821 10.6284 14.6607 10.6284 12.0051C10.6284 9.3492 12.7264 7.18883 15.3051 7.18883C17.8838 7.18883 19.9818 9.3492 19.9818 12.0051C19.9818 14.6607 17.8838 16.821 15.3051 16.821Z"
      fill="#272727"
    />
  </svg>,
  'Money',
);

export default MoneyIcon;
