import { create } from 'zustand';
import axios from '../../../../api/axios';
import { BuyFormStore, Coin, Country, Currency } from './buyForm.type';

const useBuyFormStore = create<BuyFormStore>((set) => ({
  coinsData: [],
  countryData: [],
  currencyData: [],
  paymentData: [],

  crypto: '',
  country: '',
  amountToGive: 0,
  resultCurrency: '',
  amountToReceive: 0,
  editOptions: false,
  updateCurrencyData: (data) => set({ currencyData: data }),

  setCoinsData: (data: Coin[]) => set({ coinsData: data }),
  setCurrencyData: (value: any) => set({ currencyData: value }),
  setEditOptions: (value) => set({ editOptions: value }),
  setAmountToGive: (value) => set({ amountToGive: value }),
  setAmountToReceive: (value) => set({ amountToReceive: value }),
  setCrypto: (value: any | ((prevState: string) => string)) => set({ crypto: value }),
  setCountryData: (value: BuyFormStore['countryData']) => set({ countryData: value }),
  setCountry: (value: any | ((prevState: string) => string)) => set({ country: value }),
  setPaymentData: (value: any | ((prevState: string) => string)) => set({ crypto: value }),

  setResultCurrency: (value: any | ((prevState: string) => string)) =>
    set({ resultCurrency: value }),

  fetchCryptoData: async () => {
    try {
      const { data } = await axios.get<{ items: Coin[] }>('crypto');
      set({ coinsData: data.items, crypto: data.items.length > 0 ? data.items[0].name : '' });
    } catch (error) {
      console.error('Error fetching coin data:', error);
    }
  },

  fetchCountryData: async () => {
    try {
      const { data } = await axios.get<{ items: Country[] }>('country');
      set({ countryData: data.items, country: data.items.length > 0 ? data.items[0].name : '' });
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  },

  fetchCurrencyData: async () => {
    try {
      const { data } = await axios.get<{ items: Currency[] }>('currency');
      set({
        currencyData: data.items,
        resultCurrency: data.items.length > 0 ? data.items[0].code : '',
      });
    } catch (error) {
      console.error('Error fetching currency data:', error);
    }
  },

  fetchPaymentData: async (paymentData) => {
    const {
      amountToGive,
      amountToReceive,
      countryId,
      cryptoId,
      currencyId,
      formValues,
      ipAddress,
      language,
      region,
      timezone,
    } = paymentData;

    if (!amountToGive || !amountToReceive || !countryId || !cryptoId || !currencyId) {
      alert('Fill in all the fields');
      return;
    }

    const formData = new FormData();
    formData.append('countryId', String(countryId));
    formData.append('currencyId', String(currencyId));
    formData.append('cryptoId', String(cryptoId));
    formData.append('price', String(amountToReceive));
    formData.append('priceInCrypto', String(amountToGive));
    formData.append('ip', ipAddress);
    formData.append('deviceLang', language);
    formData.append('region', region);
    formData.append('timezone', timezone);

    Object.entries(formValues).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const { data } = await axios.post('request', formData);

      set({
        paymentData: data,
      });
    } catch (error) {
      console.error('Error fetching payment data:', error);
    }
  },
}));

export default useBuyFormStore;
